import { render, staticRenderFns } from "./ListBarcodes.vue?vue&type=template&id=868dc858&scoped=true"
import script from "./ListBarcodes.vue?vue&type=script&lang=js"
export * from "./ListBarcodes.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "868dc858",
  null
  
)

export default component.exports