<template>
  <BaseLayout>
    <template #header>
      <h2>Daftar Barcodes</h2>
      <p class="hp-p1-body mb-0 text-black">Semua ({{ table.total }})</p>
      <div class="d-flex justify-content-between">
        <b-button variant="primary-2" to="/dashboards/generate-barcode">
          Tambah Barcode
        </b-button>
        <div class="row no-gutters w-15">
          <b-input-group>
            <b-input-group-prepend>
              <b-button
                variant="outline-black-50"
                class="border-right-0 pr-0 pl-2"
              >
                <i class="ri-search-line remix-icon text-primary" />
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="table.search"
              type="search"
              placeholder="Search"
              class="border-left-0"
              @input="debounceSearch"
            />
          </b-input-group>
        </div>
      </div>
    </template>
    <template #content>
      <b-table
        style="overflow: auto; white-space: nowrap"
        :items="table.items"
        :fields="fields"
        :busy.sync="isBusy"
        responsive="sm"
        show-empty
      >
        <template v-slot:cell(Aksi)="row">
          <b-button variant="danger-2" @click="deleteBarcode(row.item.id)">
            <i class="ri-delete-bin-6-line mr-1" />
            Hapus
          </b-button>
        </template>
        <template #table-busy>
          <div class="hp-p1-body text-center my-2">
            <b-spinner class="align-middle mr-8" />
            <strong>Memuat...</strong>
          </div>
        </template>
        <template #empty>
          <div class="hp-p1-body text-center my-2">
            <strong>Tidak Ada Data</strong>
          </div>
        </template>
        <template v-slot:cell(is_used)="row">
          <b-badge
            :variant="row.item.is_used === 0 ? 'info' : 'danger'"
            class="w-100"
          >
            <span class="text-center">{{
              row.item.is_used === 0 ? "Dapat digunakan" : "Sudah digunakan"
            }}</span>
          </b-badge>
        </template>
      </b-table>
      <b-pagination
        v-model="table.currentPage"
        class="mb-3"
        align="right"
        :per-page="table.perPage"
        :total-rows="table.total"
        @change="changePage"
      />
    </template>
  </BaseLayout>
</template>

<script>
import {
  BBadge,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BPagination,
  BSpinner,
  BTable,
} from "bootstrap-vue";
import tableDataMixins from "../../../../../mixins/tableDataMixins";
import manageBarcodeAPI from "../../../../../api/barcode/manageBarcodeAPI";

export default {
  name: "ListBarcodes",
  components: {
    BPagination,
    BSpinner,
    BTable,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BInputGroup,
    BBadge,
  },
  mixins: [tableDataMixins],
  data() {
    return {
      isBusy: false,
      fields: [
        {
          label: "Id",
          key: "id",
          sortable: true,
        },
        {
          label: "Status",
          key: "is_used",
        },
        {
          label: "Kode Kantong",
          key: "print_value",
        },
        {
          label: "Duplikat",
          key: "duplicates",
        },
        {
          key: "Aksi",
          sortable: false,
        },
      ],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.isBusy = true;

      manageBarcodeAPI
        .getAll({
          page: this.table.currentPage,
          per_page: this.table.perPage,
          search: this.table.search,
        })
        .then((response) => {
          this.table.items = response.data.data.data;
          this.table.total = response.data.data.total;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    async deleteBarcode(id) {
      await this.$store.dispatch("barcodeModules/destroyBarcodes", { id });
      this.table.items = this.table.items.filter((item) => item.id !== id);
      this.$bvToast.toast("Barcode Berhasil Di Hapus", {
        title: "Success",
        variant: "success",
        solid: true,
      });
    },
    changePage(page) {
      this.table.currentPage = page;
      this.fetchData();
    },
    debounceSearch() {
      clearTimeout(this.table.debounceTimer);

      this.table.debounceSearch = setTimeout(() => {
        this.onChangeSearchTable();
      }, 500);
    },
    onChangeSearchTable() {
      this.fetchData();
    },
  },
};
</script>

<style scoped></style>
